import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  notification,
} from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BgImage from "../assets/images/background_dashboard9.png";
const { Sider, Content } = Layout;

const siderStyle = {
  textAlign: "center",
  lineHeight: "120px",
  color: "#fff",
  background: "#ffffff22",
  backdropFilter: "blur(30px)",
  borderRadius: "0 5px 5px 0",
};
const contentStyle = {
  textAlign: "center",
  minHeight: 120,
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#dddddd11",
};
const layoutStyle = {
  height: "100vh",
  background: ` no-repeat center/100% url(${BgImage})`,
  backgroundSize: "cover",
};

const SignIn = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const loginUser = async (user) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/site/login`,
      user,
      {
        headers: {
          "Content-Type": "application/json",
        },
        validateStatus: function (status) {
          return true;
        },
      }
    );

    return response.data;
  };
  const { isLoading, mutate } = useMutation(loginUser, {
    onSuccess: (data) => {
      if (data.status === 200 || data.status === 302) {
        localStorage.setItem(
          process.env.REACT_APP_ACCESS_TOKEN,
          data.data.token.token
        );
        api.success({
          message: "Sucesso.",
          description: "redirecionando ao painel.",
        });
      }
      if (data.status === 401 || data.status === 400) {
        api.open({
          message: "Erro",
          description: data.message,
        });
      }
      setTimeout(1000);
      if (data.data.token.access_given === 20) {
        navigate("/assessoria");
      } else {
        navigate("/painel");
      }
    },
    onError: (error) => {
      api.open({
        message: "Erro",
        description: error,
      });
    },
  });

  // const onChange = (checked) => {
  //   console.log(`switch to ${checked}`)
  // }
  const onFinish = (values) => {
    mutate(values);
  };

  const onFinishFailed = (errorInfo) => {
    api.open({
      message: "Erro",
      description: errorInfo,
    });
  };

  return (
    <>
      {contextHolder}
      <Layout style={layoutStyle}>
        <Row style={{ height: "100%" }}>
          <Col
            flex="1 1 300px"
            style={siderStyle}
            xs={{ order: 2 }}
            md={{ order: 1 }}
          >
            <Content className="signin">
              <Row gutter={[24, 0]} justify="space-around">
                <Col
                  xs={{ span: 24, offset: 0 }}
                  lg={{ span: 20, offset: 0 }}
                  md={{ span: 24 }}
                >
                  <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className="row-col"
                  >
                    <Form.Item
                      className="username"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your credential!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="CPF"
                        style={{ height: "50px", borderRadius: "4px" }}
                      />
                    </Form.Item>

                    <Form.Item
                      className="username"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password placeholder="SENHA" />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        loading={!!isLoading}
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                      >
                        Entrar
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Content>
          </Col>
          <Col flex="1 1 800px" xs={{ order: 1 }} md={{ order: 2 }}>
            <Content style={contentStyle}>
              <Typography.Title
                style={{
                  fontSize: "70px",
                  top: "50px",
                  color: "#fff",
                  marginTop: "120px",
                }}
              >
                Be part of it.
              </Typography.Title>
            </Content>
          </Col>
        </Row>
      </Layout>
    </>
    // <Space
    //   direction="vertical"
    //   style={{ width: "100%", height: "100%" }}
    //   size={[0, 48]}
    // >
    //   {contextHolder}
    //   <Layout>
    //     <Layout style={layoutStyle}>
    //       <Sider style={siderStyle} breakpoint="md">
    // <Content className="signin">
    //   <Row gutter={[24, 0]} justify="space-around">
    //     <Col
    //       xs={{ span: 24, offset: 0 }}
    //       lg={{ span: 20, offset: 0 }}
    //       md={{ span: 24 }}
    //     >
    //       <Form
    //         onFinish={onFinish}
    //         onFinishFailed={onFinishFailed}
    //         layout="vertical"
    //         className="row-col"
    //       >
    //         <Form.Item
    //           className="username"
    //           name="username"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please input your credential!",
    //             },
    //           ]}
    //         >
    //           <Input
    //             placeholder="CPF"
    //             style={{ height: "50px", borderRadius: "4px" }}
    //           />
    //         </Form.Item>

    //         <Form.Item
    //           className="username"
    //           name="password"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please input your password!",
    //             },
    //           ]}
    //         >
    //           <Input.Password placeholder="SENHA" />
    //         </Form.Item>

    //         <Form.Item>
    //           <Button
    //             loading={!!isLoading}
    //             type="primary"
    //             htmlType="submit"
    //             style={{ width: "100%" }}
    //           >
    //             Entrar
    //           </Button>
    //         </Form.Item>
    //       </Form>
    //     </Col>
    //   </Row>
    // </Content>
    //       </Sider>
    //       <Content style={contentStyle}>
    //         <Typography.Title
    //           style={{
    //             fontSize: "70px",
    //             top: "50px",
    //             color: "#fff",
    //             marginTop: "120px",
    //           }}
    //         >
    //           Be part of it.
    //         </Typography.Title>
    //       </Content>
    //     </Layout>
    //   </Layout>
    // </Space>
  );
};

export default SignIn;
