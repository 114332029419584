import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Col, Form, Input, Row, notification } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  useProfileOnlineFetchRequest,
  useProfileUpdateRequest,
} from "./../../Hooks/profile/useProfile.query";

const Update = () => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { data: dataProfile, isLoading } = useProfileOnlineFetchRequest();
  const profile = dataProfile?.data?.data;
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading: saving } = useMutation(
    useProfileUpdateRequest,
    {
      onSettled: () => {
        console.log("onSettled");
      },
      onSuccess: () => {
        queryClient.invalidateQueries("manager-profile");
        api.open({
          message: "Sucesso",
          description: "Plano criado com sucesso.",
        });
        form.resetFields();
        navigate("/perfil");
      },
      onError: () => {},
    }
  );
  // console.log(Object.entries(profile || {}));
  const onFinish = (dataForm) => {
    const data = {
      values: dataForm,
      id: profile.id,
    };
    console.log("..", data);
    mutateAsync(data);
  };
  return (
    <>
      <div className="layout-content">
        {contextHolder}
        <Content className="p-0">
          <Row gutter={[24, 2]} justify="left" span="4">
            <Col xs={24} xl={24} md={24}>
              <Card
                bordered={true}
                className="criclebox tablespace mb-24"
                title="Editar"
              >
                <Row className="my-25 text-center">
                  <Col xs={24} xl={24} md={24}>
                    <Form
                      layout="vertical"
                      form={form}
                      name="create-user"
                      onFinish={onFinish}
                      style={{ paddingLeft: "20px", paddingRight: "20px" }}
                      fields={[
                        {
                          name: ["name"],
                          value: profile?.name,
                        },
                        {
                          name: ["phone"],
                          value: profile?.phone,
                        },
                        {
                          name: ["whatsapp"],
                          value: profile?.whatsapp,
                        },
                        {
                          name: ["pix"],
                          value: profile?.pix,
                        },
                      ]}
                    >
                      <Form.Item
                        name="name"
                        label="Nome"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        label="Telefone"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="whatsapp"
                        label="Whatsapp"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="pix"
                        label="Pix"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </div>
    </>
  );
};

export default Update;
