import { Card } from "antd";
import React from "react";
import { useParams } from "react-router";
import { useAdviceDocFetchRequest } from "../../Hooks/advice/useAdvices.query";
import UploadToApi from "./../../components/uploads/UploadToApi";

const Documentation = () => {
  const { id } = useParams();
  const { data, isLoading } = useAdviceDocFetchRequest(id);
  const adviceDocumentation = data?.data;

  return (
    <div className="layout-content">
      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title="Documentos"
      >
        {/* {adviceDocumentation.pgdas} */}
        <UploadToApi
          label="Declaração do PGDAS (Programa Gerador do Documento de Arrecadação do Simples Nacional)"
          title="pgdas"
          id={id}
          isOk={adviceDocumentation?.pgdas}
        />
        <UploadToApi
          label="Comprovante de Residência"
          title="proof_of_address"
          id={id}
          isOk={adviceDocumentation?.proof_of_address}
        />
        <UploadToApi
          label="Certidão de Casamento"
          title="marriage_certificate"
          id={id}
          isOk={adviceDocumentation?.marriage_certificate}
        />
        <UploadToApi
          label="Documentos do Cônjuge"
          title="documents_spous"
          id={id}
          isOk={adviceDocumentation?.documents_spous}
        />
        <UploadToApi
          label="Faturamento"
          title="invoicing"
          id={id}
          isOk={adviceDocumentation?.invoicing}
        />
        <UploadToApi
          label="Cartão CNPJ"
          title="cnpj_card"
          id={id}
          isOk={adviceDocumentation?.cnpj_card}
        />
        <UploadToApi
          label="Demonstrativo de Impostos Pagos nos Últimos 12 Meses"
          title="tax_billing_for_12_months"
          id={id}
          isOk={adviceDocumentation?.tax_billing_for_12_months}
        />
        <UploadToApi
          label="Relatório Eletrônico de Receitas e Despesas"
          title="social_reeib"
          id={id}
          isOk={adviceDocumentation?.social_reeib}
        />
        <UploadToApi
          label="Declaração de Informações Socioeconômicas e Fiscais"
          title="defis"
          id={id}
          isOk={adviceDocumentation?.defis}
        />
        <UploadToApi
          label="CPF do Sócio"
          title="partner_cpf"
          id={id}
          isOk={adviceDocumentation?.partner_cpf}
        />
        <UploadToApi
          label="RG do Sócio"
          title="partner_rg"
          id={id}
          isOk={adviceDocumentation?.partner_rg}
        />
        <UploadToApi
          label="CNH do Sócio"
          title="partner_cnh"
          id={id}
          isOk={adviceDocumentation?.partner_cnh}
        />
        <UploadToApi
          label="Comprovante de Endereço do Sócio"
          title="partner_proof_of_address"
          id={id}
          isOk={adviceDocumentation?.partner_proof_of_address}
        />
        <UploadToApi
          label="Declaração de Imposto de Renda do Sócio"
          title="partner_irpf"
          id={id}
          isOk={adviceDocumentation?.partner_irpf}
        />
      </Card>
    </div>
  );
};

export default Documentation;
