import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getWalletBalance = async (value) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/wallet/wallet-balance?operation=${value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log("getWalletBalance", error.message);
  }
};

const getManagerWallet = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/profile/manager-wallet?expand=wallet`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export function useWalletAmountBalanceFetchRequest(operation) {
  return useQuery(["walletAmount"], () => getWalletBalance(operation), {
    onSuccess: (data) => {
      return data?.data;
    },
  });
}

export function useWalletExpenseBalanceFetchRequest(operation) {
  return useQuery(["walletExpense"], () => getWalletBalance(operation), {
    onSuccess: (data) => {
      return data?.data;
    },
  });
}

export function useManagerWalletFetchRequest() {
  return useQuery(["managerWallet"], () => getManagerWallet(), {
    onSuccess: (data) => {
      return data?.data;
    },
  });
}
