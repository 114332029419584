import axios from "axios";

const postUploadDocument = async (dataImage) => {
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_API}/v1/advice/uploaddoc`,
      dataImage,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const postUploadGeneric = async (dataImage) => {
  const { id, imageUrl, controller, action, ...props } = dataImage;

  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_API}/v1/${controller}/${action}`,
      { id, imageUrl, ...props },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export const useUploadDocument = async (data) => {
  return postUploadDocument(data);
};

export const useUploadGeneric = async (data) => {
  return postUploadGeneric(data);
};
