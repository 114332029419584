const status = {
  0: "Bloqueado",
  1: "Não Ativo",
  10: "Ativo",
};

const adviceStatus = {
  0: "Bloqueado",
  1: "Não Ativo",
  10: "Ativo",
};

export const formatStatus = (type) => {
  return status[type] || "";
};

export const formatStatusAdvice = (type) => {
  return adviceStatus[type] || "";
};
