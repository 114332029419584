import { Button, Card, Col, Input, Row, Space } from "antd";
import React, { useState } from "react";
import Lottie from "react-lottie";
import animationData from "../../components/Lotties/calculate";
import { formatCurrency } from "../../helpers/moneyFormat";
import "./styles.css";

const arrSimulator = [
  {
    percentageMonth: 0,
    monthGain: 0,
    monthacumuladoGain: 0,
    wallet: 0,
  },
];
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Simulator = () => {
  const [valorInvestimento, setValorInvestimento] = useState("");
  const [mesesInvestimento, setMeseInvestimento] = useState(12);
  const [taxasMensais, setTaxasMensais] = useState(Array(12).fill(0));
  const [percentageMensais, setPercentageMensais] = useState([]);
  const [ganhosMensais, setGanhosMensais] = useState([]);
  const [ganhosAcumulados, setGanhosAcumulados] = useState([]);
  const [saldoGanhosAcumulados, setSaldoGanhosAcumulados] = useState([]);
  const [animationCalculate, setAnimationCalculation] = useState(false);
  const [tableCalculate, setTableCalculate] = useState(false);
  const [datatable, setDataTable] = useState([arrSimulator]);

  const handleChangeInvestimento = (event) => {
    setValorInvestimento(event.target.value);
  };

  const handleChangeMesesInvestimento = (event) => {
    setMeseInvestimento(event.target.value);
  };

  const handleChangeTaxaMensal = (event, index) => {
    const newTaxasMensais = [...taxasMensais];
    newTaxasMensais[index] = parseFloat(event.target.value);
    setTaxasMensais(newTaxasMensais);
  };

  function taxRanndomMoth() {
    const minValue = 2.7;
    const maxValue = 3.8;

    const random = Math.random() * (maxValue - minValue) + minValue;
    console.log(random / 100);
    return random / 100;
  }

  const calcularGanhos = async () => {
    let valorInvestido = parseFloat(valorInvestimento);
    let valorInvestidoParaGanhoAcumulado = parseFloat(valorInvestimento);

    const newGanhosMensais = [];
    const newGanhosAcumulados = [];
    const newSaldoGanhosAcumulados = [];
    const newPercentage = [];

    for (let i = 0; i < mesesInvestimento; i++) {
      // const ganhoMensalComSaque = valorInvestido * 0.031;
      const tax = taxRanndomMoth();
      const ganhoMensalComSaque = valorInvestido * tax;

      // const ganhoMensalAcumulado = valorInvestidoParaGanhoAcumulado * 0.031;
      const ganhoMensalAcumulado = valorInvestidoParaGanhoAcumulado * tax;
      valorInvestidoParaGanhoAcumulado += ganhoMensalAcumulado;
      newGanhosMensais.push(ganhoMensalComSaque);
      newGanhosAcumulados.push(ganhoMensalAcumulado);
      newSaldoGanhosAcumulados.push(valorInvestidoParaGanhoAcumulado);
      newPercentage.push(tax * 100);
    }

    setTableCalculate(false);
    setAnimationCalculation(true);
    await delay(2000);
    setTableCalculate(true);
    setGanhosMensais(newGanhosMensais);
    setGanhosAcumulados(newGanhosAcumulados);
    setSaldoGanhosAcumulados(newSaldoGanhosAcumulados);
    setPercentageMensais(newPercentage);
    setAnimationCalculation(false);
  };
  ///

  const totalPercentage = percentageMensais.reduce(
    (accumulator, value) => accumulator + value,
    0
  );
  const totalMonth = ganhosMensais.reduce(
    (accumulator, value) => accumulator + value,
    0
  );
  const cumulatieTotal = ganhosAcumulados.reduce(
    (accumulator, value) => accumulator + value,
    0
  );
  const getLastArrItem = (arr) => {
    return arr[arr.length - 1];
  };

  return (
    <div className="layout-content">
      <Row gutter={[24, 5]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Simule seus investimentos"
          >
            <Row
              justify="center"
              gutter={[16, 24]}
              style={{
                marginBottom: "25px",
                marginTop: "25px",
              }}
            >
              <Space direction="vertical">
                <Col span={16}>
                  <Input
                    addonBefore="Valor a ser investido"
                    placeholder="Valor"
                    value={valorInvestimento}
                    onChange={handleChangeInvestimento}
                  />
                </Col>
                <Col span={16}>
                  <Input
                    addonBefore="Meses de investimento"
                    placeholder="meses"
                    value={mesesInvestimento}
                    onChange={handleChangeMesesInvestimento}
                  />
                </Col>
                <Col span={16}>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={calcularGanhos}
                    style={{ width: "100%" }}
                  >
                    Simular
                  </Button>
                </Col>
              </Space>
            </Row>

            {animationCalculate ? (
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height="auto"
                width="40%"
              />
            ) : (
              ""
            )}
            {tableCalculate ? (
              <div
                style={{
                  overflow: "auto",
                }}
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th>% mensal</th>
                      <th>Ganhos mensais com saque</th>
                      <th>Ganhos acumulados mês a mês</th>
                      <th>Saldo acumulado</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>
                        Percentual acumulado em {mesesInvestimento} meses:
                        {totalPercentage.toFixed(2)} %
                      </th>
                      <th>
                        Total acumulado com saque {formatCurrency(totalMonth)}
                      </th>
                      <th>
                        Total acumulado sem saque{" "}
                        {formatCurrency(cumulatieTotal)}
                      </th>
                      <th>
                        Saldo acumulado{" "}
                        {formatCurrency(getLastArrItem(saldoGanhosAcumulados))}
                      </th>
                    </tr>
                  </tfoot>
                  <tbody>
                    <tr>
                      <td>
                        {percentageMensais.map((ganho, index) => (
                          <tr key={index}>
                            <td>{ganho.toFixed(2)} %</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        {ganhosMensais.map((ganho, index) => (
                          <tr key={index}>
                            <td>{formatCurrency(ganho)}</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        {ganhosAcumulados.map((ganho, index) => (
                          <tr key={index}>
                            <td>{formatCurrency(ganho)}</td>
                          </tr>
                        ))}
                      </td>
                      <td>
                        {saldoGanhosAcumulados.map((ganho, index) => (
                          <tr key={index}>
                            <td>{formatCurrency(ganho)}</td>
                          </tr>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Simulator;
