import { CheckSquareOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Tag,
  notification,
} from "antd";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input";
import { NavLink, useNavigate } from "react-router-dom";
import {
  useCopysFetchRequest,
  useNewAportCopyPostRequest,
} from "../../Hooks/copy/useCopy.query";
import { TableCopy } from "../../components/Table";
import UploadDefault from "../../components/uploads/UploadDefault";
import { convertTimestampToDate } from "../../helpers/convertTimestampToDate";
import { slugify } from "../../helpers/slugify";
import { formatCurrency } from "./../../helpers/moneyFormat";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const date = new Date();
const dateExtencil = `${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}`;
const arrForm = [
  {
    name: "price_sale",
    label: "Valor novo aporte",
    required: false,
  },
];
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};
const Copy = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [openModal, setOpenModal] = useState(false);
  const [openModalExtra, setOpenModalExtra] = useState(false);
  const [contentModal, setContentModal] = useState("");
  const { data, isLoading } = useCopysFetchRequest();
  const queryClient = useQueryClient();

  const dataAdvice = data?.map((copy, index) => {
    return {
      id: index,
      key: copy.id,
      name: copy.name,
      voucher: copy.voucher,
      amount: copy.price_sale,
      date: copy.created_at,
      new: copy.new_aport,
      status: copy.status,
    };
  });

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (_, text) => <>{text.name}</>,
      width: "80%",
      // ...getColumnSearchProps("name"),
    },
    {
      title: "Ganho da venda",
      dataIndex: "gain",
      key: "gain",
      render: (_, { amount }) => <>{formatCurrency(amount * 0.6)}</>,
    },
    {
      title: "Valor Entrada",
      dataIndex: "amount",
      key: "amount",
      render: (text) => formatCurrency(text),
    },
    {
      title: "Data de entrada",
      dataIndex: "date",
      key: "date",
      render: (text) => convertTimestampToDate(text),
    },
    {
      title: "Comprovante",
      dataIndex: "voucher",
      key: "voucher",
      render: (_, doc) => (
        <>
          {!doc.voucher ? (
            <UploadDefault
              id={doc.key}
              title={dateExtencil}
              label="Enviar"
              folder={`copy/${slugify(doc.name)}-${doc.key}`}
              controller="copy"
              action="uploadvoucher"
            />
          ) : (
            <CheckSquareOutlined />
          )}
        </>
      ),
      width: "10%",
    },
    {
      title: "Novo Aporte",
      dataIndex: "new",
      key: "new",
      render: (_, doc) => (
        <>
          <Button
            type="primary"
            size="large"
            onClick={() => handleOpenNewAport(doc)}
          >
            Novo
          </Button>
        </>
      ),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <>
          {status === 0 && <Tag color="gray">AGUARDANDO</Tag>}
          {status === 10 && <Tag color="green">APROVADO</Tag>}
          {status === 1 && <Tag color="volcano">BLOQUEADO</Tag>}
        </>
      ),
      width: "10%",
    },
  ];

  const handleOpenNewAport = (content) => {
    setOpenModal(true);
    setContentModal(content);
  };
  const handleOpenNewAportModal = (content) => {
    setOpenModalExtra(true);
  };

  const { mutateAsync } = useMutation(useNewAportCopyPostRequest, {
    onSettled: () => {
      console.log("onSettled");
      queryClient.invalidateQueries("manager-copys");
    },
    onSuccess: (data) => {
      form.resetFields();
      api.open({
        message: "Sucesso",
        description: "Lançado com sucesso!",
      });
      queryClient.invalidateQueries("manager-copys");
      setOpenModalExtra(false);
      // setOpenModal(false);
      // setContentModal(data.data.data.id);
      // delay(1000);
      // setOpenModal(true);
      // navigate("/copy");
    },
    onError: (err) => {
      form.resetFields();
      console.log("<>", err);
    },
  });
  const onFinish = (values) => {
    var amount = values.price_sale;
    amount = amount.replace("US$ ", "");
    const data = {
      id: values.id,
      price_sale: amount,
    };
    mutateAsync(data);
  };
  return (
    <div className="layout-content">
      {contextHolder}
      <Col>
        <Button type="primary" className="">
          <NavLink to="criar">+ Cadastrar novo</NavLink>
        </Button>
      </Col>
      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title="Investidores"
      >
        <div
          style={{
            overflow: "auto",
          }}
        >
          <Table
            loading={isLoading ? true : false}
            columns={columns}
            dataSource={dataAdvice}
            pagination={false}
            className="ant-border-space custom-row-hover"
            bordered
          />
        </div>
      </Card>
      <Modal
        title={contentModal?.name}
        centered
        open={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        width={1000}
        destroyOnClose={true}
        footer={null}
      >
        <Button
          type="primary"
          className=""
          onClick={() => handleOpenNewAportModal(contentModal?.key)}
        >
          + Cadastrar novo
        </Button>
        <TableCopy data={contentModal} />
        <Modal
          title=""
          centered
          open={openModalExtra}
          onOk={() => setOpenModalExtra(false)}
          onCancel={() => setOpenModalExtra(false)}
          destroyOnClose={true}
          footer={null}
        >
          <Row className="my-25 text-center">
            <Form
              layout="vertical"
              form={form}
              name="create-user"
              onFinish={onFinish}
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
              {...tailLayout}
            >
              <Form.Item name="id" initialValue={contentModal?.key} hidden>
                <Input />
              </Form.Item>
              <Form.Item
                className=""
                clearable
                name="price_sale"
                label="Valor novo aporte"
              >
                <CurrencyInput
                  autoFocus={true}
                  className="form-input px-4 py-3 rounded-full"
                  prefix=" US$ "
                  id="input-example"
                  decimalsLimit="2"
                  decimalSeparator=","
                  thousandSeparator="."
                  onValueChange={(value, name) => console.log(value, name)}
                  style={{
                    margin: "-5px 0px",
                    height: "50px",
                    border: "0px transparent",
                    width: "100%",
                    borderRadius: "10px",
                    padding: "0 0 0 0px",
                  }}
                  min="0"
                />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  // loading={!!isLoading}
                  style={{ width: "100%", marginRight: 0 }}
                  type="primary"
                  htmlType="submit"
                >
                  CRIAR
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </Modal>
        <br />
      </Modal>
    </div>
  );
};

export default Copy;
