import { Button, Card, Col, Progress, Table, Tooltip } from "antd";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useAdviceFetchRequest } from "../../Hooks/advice/useAdvices.query";

const Advice = () => {
  const { data, isLoading } = useAdviceFetchRequest();
  const advice = data?.data?.data;

  // const { data: docData, isLoading: loadingData } = useAdviceDocFetchRequest(
  //   advice?.documentation?.id
  // );
  // const adviceDocumentation = data?.data;

  function calcularPorcentagemItensEnviados(itensEnviados) {
    if (itensEnviados < 0 || itensEnviados > 17) {
      return;
    }

    if (itensEnviados && typeof itensEnviados === "object") {
      let quantidadeCamposNaoNulos = 0;

      Object.keys(itensEnviados).forEach((campo) => {
        if (itensEnviados[campo] !== null) {
          quantidadeCamposNaoNulos++;
        }
      });
      const realQtde = quantidadeCamposNaoNulos - 2;

      const totalItens = 14;
      const porcentagemEnviada = (realQtde / totalItens) * 100;
      return (
        <Tooltip
          title={
            porcentagemEnviada === 100
              ? "Todos documentos enviados"
              : `restam documentos para enviar`
          }
        >
          <Progress percent={Math.round(porcentagemEnviada)} size="small" />
        </Tooltip>
      );
    } else {
      return;
    }
  }

  const dataAdvice = advice?.map((advice, index) => {
    return {
      id: index,
      key: advice.id,
      name: advice.name,
      status: advice.status,
      doc: advice?.documentation?.id,
      docQntd: advice?.documentation,
      send: advice?.id,
    };
  });
  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (_, text) => text.name,
      width: "80%",
      // ...getColumnSearchProps("name"),
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   width: "10%",
    // },
    {
      title: "Documentos",
      dataIndex: "doc",
      key: "doc",
      render: (_, doc) => (
        <>
          <Link to={`documentacao/${doc.doc}`}>Cadastrar Documento</Link>
        </>
      ),
      width: "10%",
    },
    {
      title: "Status Documentação",
      dataIndex: "env",
      key: "env",
      render: (_, doc) => <>{calcularPorcentagemItensEnviados(doc.docQntd)}</>,
      width: "10%",
    },
    {
      title: "Enviar para analise",
      dataIndex: "send",
      key: "send",
      render: (_, doc) => (
        <>
          <Link to={`enviar/${doc.doc}`}>Enviar</Link>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    // {
    //   title: "Enviar",
    //   dataIndex: "send",
    //   key: "send",
    //   render: () => <Link>Enviar</Link>,
    // },
    // {
    //   title: "Etapa",
    //   dataIndex: "step",
    //   key: "step",
    //   render: (_) => (
    //     <Steps
    //       items={[
    //         {
    //           title: "",
    //           description: "Ficha",
    //           status: "finish",
    //           icon: <SnippetsOutlined style={{ fontSize: "14px" }} />,
    //         },
    //         {
    //           title: "",
    //           description: "Documentos",
    //           status: "finish",
    //           icon: <FilePdfOutlined style={{ fontSize: "14px" }} />,
    //         },
    //         {
    //           title: "Enviado",
    //           status: "process",
    //           icon: <SendOutlined style={{ fontSize: "14px" }} />,
    //         },
    //         {
    //           title: "Done",
    //           status: "wait",
    //           icon: <DollarOutlined style={{ fontSize: "14px" }} />,
    //         },
    //       ]}
    //     />
    //   ),
    // },
  ];

  return (
    <div className="layout-content">
      <Col>
        <Button type="primary" className="">
          <NavLink to="criar">+ Cadastrar novo</NavLink>
        </Button>
      </Col>
      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title="Investidores"
      >
        <div
          style={{
            overflow: "auto",
          }}
        >
          <Table
            loading={isLoading ? true : false}
            columns={columns}
            dataSource={dataAdvice}
            pagination={false}
            className="ant-border-space custom-row-hover"
            bordered
          />
        </div>
      </Card>
    </div>
  );
};

export default Advice;
