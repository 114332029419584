import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Switch,
  notification,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useAdvicePostRequest } from "../../Hooks/advice/useAdvices.query";
const { Search } = Input;

const tailLayout = {
  wrapperCol: { offset: 6, span: 12 },
};

const CreateAdvice = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [maritialSatus, setMaritialSatus] = useState(null);
  const [simpleNational, setSimpleNational] = useState(0);
  const [presumedRealProfit, setPresumedRealProfit] = useState(0);
  const queryClient = useQueryClient();

  const arrForm = [
    {
      name: "name",
      label: "Nome",
      required: true,
    },
    {
      name: "business_name",
      label: "Nome da empresa",
      required: false,
    },
    {
      name: "registration_number",
      label: "CNPJ/CPF",
      required: true,
    },
    {
      name: "phone",
      label: "Telefone",
      required: false,
    },
    {
      name: "whatsapp",
      label: "WhatsApp",
      required: true,
    },
    {
      name: "email",
      label: "E-mail",
      required: true,
    },
  ];

  const { mutateAsync } = useMutation(useAdvicePostRequest, {
    onSettled: () => {
      console.log("onSettled");
    },
    onSuccess: (data) => {
      api.open({
        message: "Sucesso",
        description: data.message,
      });
      queryClient.invalidateQueries("advicesManager");
      navigate("/assessoria");
    },
    onError: () => {},
  });

  const onFinish = (data) => {
    const dataPost = {
      simple_national: simpleNational,
      presumed_real_profit: presumedRealProfit,
      marital_status: maritialSatus,
      ...data,
    };
    mutateAsync(dataPost);
  };

  const handleChangeSimpleNational = () => {
    setSimpleNational(1);
  };
  const handleChangePresumedRealProfit = () => {
    setPresumedRealProfit(1);
  };
  const handleChangeMaritalStatus = (value) => {
    setMaritialSatus(value);
  };

  const handleSeachPostal = async (postalcode) => {
    const { data } = await axios.get(
      `https://brasilapi.com.br/api/cep/v2/${postalcode}`
    );
    form.setFieldsValue({
      public_place: data.street,
      city: data.city,
      state: data.state,
      complement: `Bairro: ${data.neighborhood} - `,
    });
  };
  return (
    <div className="layout-content">
      {contextHolder}
      <Content className="p-0">
        <Row gutter={[24, 2]} justify="left" span="4">
          <Col xs={24} xl={24} md={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title="Cadastrar"
            >
              <Row className="my-25 text-center">
                <Col xs={24} xl={24} md={24}>
                  <Form
                    layout="vertical"
                    form={form}
                    name="create-user"
                    onFinish={onFinish}
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  >
                    {arrForm.map((list, index) => {
                      return (
                        <Form.Item
                          key={index + 1}
                          name={list.name}
                          label={list.label}
                          rules={[{ required: list?.required }]}
                        >
                          <Input />
                        </Form.Item>
                      );
                    })}

                    <Form.Item
                      name="postal_code"
                      label="CEP"
                      rules={[{ required: true }]}
                    >
                      <Search
                        placeholder="input search text"
                        onSearch={handleSeachPostal}
                        enterButton
                      />
                    </Form.Item>
                    <Form.Item
                      name="public_place"
                      label="Logadouro"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="number"
                      label="Número"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="complement"
                      label="Complemento"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="city"
                      label="Cidade"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="state"
                      label="Estado"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Estado civil"
                      style={{
                        display: "inline-block",
                        width: "calc(50%)",
                      }}
                    >
                      <Select onChange={handleChangeMaritalStatus}>
                        <Select.Option value="solteiro">Solteiro</Select.Option>
                        <Select.Option value="casado">Casado</Select.Option>
                        <Select.Option value="divorciado">
                          Divorciado
                        </Select.Option>
                        <Select.Option value="viuvo">Viúvo</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Simples Nacional"
                      valuePropName="checked"
                      style={{
                        display: "inline-block",
                        width: "calc(25%)",
                      }}
                    >
                      <Switch
                        className="mb-5"
                        defaultChecked={simpleNational}
                        onChange={handleChangeSimpleNational}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Lucro presumido ou real"
                      style={{
                        display: "inline-block",
                        width: "calc(25%)",
                      }}
                    >
                      <Switch
                        className="mb-5"
                        defaultChecked={presumedRealProfit}
                        onChange={handleChangePresumedRealProfit}
                      />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                      <Button
                        // loading={!!isLoading}
                        style={{ width: "100%", marginRight: 0 }}
                        type="primary"
                        htmlType="submit"
                      >
                        CRIAR
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default CreateAdvice;
