import { CheckSquareOutlined } from "@ant-design/icons";
import { Table, Tag } from "antd";
import React from "react";
import { convertTimestampToDate } from "../../helpers/convertTimestampToDate";
import { slugify } from "../../helpers/slugify";
import UploadDefault from "../uploads/UploadDefault";

const date = new Date();
const dateExtencil = `${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}`;

const TableCopy = ({ data }) => {
  let obj = JSON.parse(data.new);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      rowScope: "row",
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (date) => convertTimestampToDate(date),
    },
    {
      title: "Valor",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$ ${amount}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { isAproved }) => (
        <>
          {isAproved === 0 && <Tag color="gray">Aguardando Liberação</Tag>}
          {isAproved === 1 && <Tag color="green">Liberado</Tag>}
        </>
      ),
    },
    {
      title: "Comprovante",
      dataIndex: "voucher",
      key: "voucher",
      width: "20%",
      render: (_, text) => (
        <>
          {text.voucher ? (
            <CheckSquareOutlined />
          ) : (
            <UploadDefault
              id={data.key}
              title={text.date}
              label={`Enviar`}
              folder={`copy/${slugify(data.name)}-${data.key}`}
              controller="copy"
              action="uploadvouchernewaport"
              idNewAport={text.id}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={obj}
        className="ant-border-space custom-row-hover"
        bordered
        pagination={{
          pageSize: 10,
        }}
        scroll={{
          y: 540,
        }}
      />
    </>
  );
};

export default TableCopy;
