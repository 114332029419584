import { Badge, Button, Card, Descriptions, Modal } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAdviceBankFetchRequest } from "../../Hooks/advice/useAdvices.query";

const DataAdviceBank = () => {
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const { data, isLoading } = useAdviceBankFetchRequest(id);
  const documents = data?.data?.data;

  const listDoc = [
    documents?.documentation?.id,
    documents?.documentation?.pgdas,
    documents?.documentation?.proof_of_address,
    documents?.documentation?.marriage_certificate,
    documents?.documentation?.documents_spous,
    documents?.documentation?.invoicing,
    documents?.documentation?.cnpj_card,
    documents?.documentation?.tax_billing_for_12_months,
    documents?.documentation?.social_contract,
    documents?.documentation?.social_reeib,
    documents?.documentation?.defis,
    documents?.documentation?.partner_cpf,
    documents?.documentation?.partner_rg,
    documents?.documentation?.partner_cnh,
    documents?.documentation?.partner_proof_of_address,
    documents?.documentation?.partner_irpf,
    documents?.documentation?.advice_busines,
  ];

  const adviceStatus = {
    0: "AGUARDANDO",
    1: "REPROVADO",
    10: "APROVADO",
  };

  const adviceTag = {
    0: "default",
    1: "error",
    10: "success",
  };

  const handleAprove = {};

  return (
    <div className="layout-content">
      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title={`${documents?.name}  [${documents?.business_name}]`}
      >
        <Button type="primary" onClick={() => setOpenModal(true)}>
          Aprovar
        </Button>
        <Descriptions title="" bordered>
          <Descriptions.Item label="Status" span={3}>
            <Badge
              status={adviceTag[documents?.status]}
              text={adviceStatus[documents?.status]}
            />{" "}
          </Descriptions.Item>
          <Descriptions.Item label="Nome">{documents?.name}</Descriptions.Item>
          <Descriptions.Item label="Nome da empresa">
            {documents?.business_name}
          </Descriptions.Item>
          <Descriptions.Item label="CNPJ/CPF">
            {documents?.registration_number}
          </Descriptions.Item>
          <Descriptions.Item label="Endereço">
            {documents?.public_place}, {documents?.number} <br />{" "}
            {documents?.complement}
            <br /> {documents?.city} - {documents?.state} -{" "}
            {documents?.postal_code}
          </Descriptions.Item>
          <Descriptions.Item label="Telefone">
            {documents?.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Whatsapp">
            {documents?.whatsapp}
          </Descriptions.Item>
          <Descriptions.Item label="email">
            {documents?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            {documents?.marital_status}
          </Descriptions.Item>
          <Descriptions.Item label="presumed_real_profit">
            {documents?.presumed_real_profit ? "Sim" : "Não"}
          </Descriptions.Item>
          <Descriptions.Item label="simple_national">
            {documents?.simple_national ? "Sim" : "Não"}
          </Descriptions.Item>
          <Descriptions.Item label="Data Cadastro">
            {documents?.created_at}
          </Descriptions.Item>
        </Descriptions>

        <Modal
          title="{contentModal?.name}"
          centered
          open={openModal}
          onOk={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          width={1000}
          destroyOnClose={true}
          footer={null}
        ></Modal>

        {/* <List
          header={<div>Documentos</div>}
          bordered
          dataSource={listDoc}
          renderItem={(item, index) => (
            <List.Item key={index + 1}>
              <Typography.Text mark>[ITEM]</Typography.Text> {item}
            </List.Item>
          )}
        /> */}
      </Card>
    </div>
  );
};

export default DataAdviceBank;
