import { LinkOutlined } from "@ant-design/icons";
import { Card, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useAdviceFetchRequest } from "../../Hooks/advice/useAdvices.query";

const AdviceBank = () => {
  const { data, isLoading } = useAdviceFetchRequest();
  const advice = data?.data?.data;

  const dataAdvice = advice?.map((advice, index) => {
    return {
      id: index,
      key: advice.id,
      name: advice.name,
      status: advice.status,
      doc: advice?.documentation?.id,
      docQntd: advice?.documentation,
      send: advice?.id,
    };
  });

  const columns = [
    {
      title: "Ver Dados",
      dataIndex: "link",
      key: "link",
      render: (_, text) => (
        <Link to={`ver/${text.key}`}>
          <LinkOutlined /> Ver Dados
        </Link>
      ),
      width: "5%",
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (_, text) => text.name,
      width: "80%",
      // ...getColumnSearchProps("name"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];
  return (
    <div className="layout-content">
      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title="Investidores"
      >
        <div className="table-responsive">
          <Table
            loading={isLoading ? true : false}
            columns={columns}
            dataSource={dataAdvice}
            pagination={false}
            className="ant-border-space custom-row-hover"
            bordered
          />
        </div>
      </Card>
    </div>
  );
};

export default AdviceBank;
