import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Progress, Row, notification } from "antd";
import axios from "axios";
import { IKContext, IKUpload } from "imagekitio-react";
import React, { useRef, useState } from "react";
import { useUploadDocument } from "../../Hooks/uploads/useUpload.query";

const UploadToApi = ({ label, title, id, isOk }) => {
  const reftest = useRef(null);
  const [api, contextHolder] = notification.useNotification();
  const [file, setFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const queryClient = useQueryClient();
  // IMAGEKIT UPLOAD
  const publicKey = "public_Hqt+AgHm0gjcWTX7lFzrSP0QwhE=";
  const urlEndpoint = "https://ik.imagekit.io/qqrtx9mgqo/";
  const authenticationEndpoint = `${process.env.REACT_APP_API}/v1/site/auth`;

  const gridStyle = {
    width: "100%",
    margin: "10px 5px",
    border: isOk ? "solid greenyellow 2px" : "",
  };

  const bodyStyle = {
    padding: "10px 10px",
  };

  const { mutateAsync: uploadDocument } = useMutation(useUploadDocument, {
    onSettled: () => {
      queryClient.invalidateQueries("advicesDocManager");
    },
    onSuccess: () => {
      queryClient.invalidateQueries("advicesDocManager");
    },
  });
  const onSuccess = (res) => {
    const document = res.url;
    const attr = title;
    const dataDocument = {
      document,
      id,
      title,
      attr,
    };
    api.success({
      message: "Sucesso",
      description: "documento enviado com sucesso.",
    });
    uploadDocument(dataDocument);
  };

  const onUploadStart = (evt) => {};

  const onUploadProgress = (event) => {
    const percent = Math.floor((event.loaded / event.total) * 100);
    setProgress(percent);
  };

  const onError = (err) => {
    console.log("error: ", err);
    // toast.error(`${err}`, {
    //   position: toast.POSITION.TOP_CENTER
    // });
  };

  const handleUpload = () => {
    const formData = new FormData();

    setUploading(true);

    axios
      .options(`${process.env.REACT_APP_API}/site/test`, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      })
      .then((res) => res.json())
      .then(() => {
        setFile();
      })
      .catch((err) => {
        console.log("erro", err);
      });
  };

  const props = {
    // action: `${process.env.REACT_APP_API}/site/test`,

    maxCount: 1,
    file,
    // onchange({ file }) {
    //   console.log(">>>", file);
    // },
  };
  return (
    <>
      {contextHolder}
      <Row>
        <Card
          title={label}
          style={gridStyle}
          bordered={true}
          bodyStyle={bodyStyle}
        >
          <IKContext
            publicKey={publicKey}
            urlEndpoint={urlEndpoint}
            authenticationEndpoint={authenticationEndpoint}
          >
            <IKUpload
              fileName={`${label}${id}`}
              onError={onError}
              onSuccess={onSuccess}
              useUniqueFileName={false}
              folder={`/advice/${id}`}
              onUploadStart={onUploadStart}
              onUploadProgress={onUploadProgress}
              style={{ display: "none" }}
              inputRef={reftest}
            />
            {reftest && (
              <>
                {isOk ? (
                  <>
                    <CheckCircleOutlined
                      style={{ margin: "0 10px 0 0 ", color: "yellowgreen" }}
                    />
                    <span style={{ margin: "0 20px 0 0" }}>
                      Documento enviado
                    </span>
                  </>
                ) : (
                  <>
                    <CloseCircleOutlined
                      style={{ margin: "0 10px 0 0 ", color: "yellowgreen" }}
                    />
                    <span style={{ margin: "0 20px 0 0" }}>
                      Documento não enviado
                    </span>
                  </>
                )}
                <Button
                  onClick={() => reftest.current.click()}
                  icon={<CloudUploadOutlined />}
                >
                  Enviar
                </Button>
              </>
            )}
          </IKContext>

          <Row>
            {progress > 0 ? (
              <Progress
                percent={progress}
                strokeColor={{
                  "0%": "#108ee9",
                  "80%": "#108ee9",
                  "100%": "#87d068",
                }}
                size={20}
              />
            ) : null}
          </Row>
        </Card>
      </Row>
    </>
    // <>
    //   <Upload {...props}>
    //     <Button icon={<Billing />}>Upload</Button>
    //   </Upload>
    //   <Button onClick={handleUpload}>up</Button>
    // </>
  );
};

export default UploadToApi;
