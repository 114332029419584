export const dateEndContract = (data) => {
  if (!data) return "Não informado";
  // Divide a string da data em dia, mês e ano
  var partesData = data.split("/");
  var dia = parseInt(partesData[0], 10);
  var mes = parseInt(partesData[1], 10);
  var ano = parseInt(partesData[2], 10);

  // Cria um objeto de data com a data fornecida
  var dataFornecida = new Date(ano, mes - 1, dia); // Subtraímos 1 do mês, pois em JavaScript os meses são indexados de 0 a 11

  // Adiciona 8 meses à data fornecida
  dataFornecida.setMonth(dataFornecida.getMonth() + 8);

  // Obtém o dia, mês e ano da nova data
  var diaNovaData = dataFornecida.getDate();
  var mesNovaData = dataFornecida.getMonth() + 1; // Adicionamos 1 ao mês, pois em JavaScript os meses são indexados de 0 a 11
  var anoNovaData = dataFornecida.getFullYear();

  // Formata a nova data no formato "dd/mm/AAAA"
  var novaDataFormatada =
    diaNovaData.toString().padStart(2, "0") +
    "/" +
    mesNovaData.toString().padStart(2, "0") +
    "/" +
    anoNovaData.toString();

  // Retorna a nova data formatada
  return novaDataFormatada;
};

export const daysToEndContract = (to) => {
  if (!to) return "0";

  var from = new Date().toLocaleDateString("pt-BR");
  var initialDate = from.split("/");
  var endDate = to.split("/");

  var dayInitial = parseInt(initialDate[0], 10);
  var monthInitial = parseInt(initialDate[1], 10);
  var yearInitial = parseInt(initialDate[2], 10);

  var dayEnd = parseInt(endDate[0], 10);
  var monthEnd = parseInt(endDate[1], 10);
  var yearEnd = parseInt(endDate[2], 10);

  var dateInitialObj = new Date(yearInitial, monthInitial - 1, dayInitial);
  var dateEndObj = new Date(yearEnd, monthEnd - 1, dayEnd);

  var betweenMilliseconds = dateInitialObj - dateEndObj;

  var daysToEnd = Math.floor(betweenMilliseconds / (1000 * 60 * 60 * 24));

  if (daysToEnd <= 9) {
    return `ATENÇÃO ${daysToEnd}`;
  }
  return daysToEnd;
};
