import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Col, Form, Input, Row, notification } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useNavigate } from "react-router";
import { useCopyPostRequest } from "../../Hooks/copy/useCopy.query";

const tailLayout = {
  wrapperCol: { offset: 6, span: 12 },
};
const arrForm = [
  {
    name: "name",
    label: "Nome",
    required: true,
  },
  {
    name: "price_sale",
    label: "Preço Vendido",
    required: false,
  },
];

const CreateCopy = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();

  const { mutateAsync } = useMutation(useCopyPostRequest, {
    onSettled: () => {
      console.log("onSettled");
    },
    onSuccess: (data) => {
      api.open({
        message: "Sucesso",
        description: "data.message",
      });
      queryClient.invalidateQueries("manager-copys");
      navigate("/copy");
    },
    onError: (err) => {
      console.log("<>", err);
    },
  });
  const onFinish = (data) => {
    mutateAsync(data);
  };

  return (
    <div className="layout-content">
      {contextHolder}
      <Content className="p-0">
        <Row gutter={[24, 2]} justify="left" span="4">
          <Col xs={24} xl={24} md={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title="Cadastrar"
            >
              <Row className="my-25 text-center">
                <Form
                  layout="vertical"
                  form={form}
                  name="create-user"
                  onFinish={onFinish}
                  style={{ paddingLeft: "20px", paddingRight: "20px" }}
                >
                  {arrForm.map((list, index) => {
                    return (
                      <Form.Item
                        key={index + 1}
                        name={list.name}
                        label={list.label}
                        rules={[{ required: list.required }]}
                      >
                        <Input />
                      </Form.Item>
                    );
                  })}
                  <Form.Item {...tailLayout}>
                    <Button
                      // loading={!!isLoading}
                      style={{ width: "100%", marginRight: 0 }}
                      type="primary"
                      htmlType="submit"
                    >
                      CRIAR
                    </Button>
                  </Form.Item>
                </Form>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default CreateCopy;
