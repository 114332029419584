import {
  CalendarOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FallOutlined,
  FilePdfOutlined,
  LineChartOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Avatar,
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  List,
  Row,
  notification,
} from "antd";
import Title from "antd/lib/typography/Title";
import axios from "axios";
import "dayjs/locale/pt-br";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate, useParams } from "react-router-dom";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { useManagerFetchRequest } from "../../Hooks/manager/useManager.query";
import BgProfile from "../../assets/images/bg-profile.jpg";
import convesionImg from "../../assets/images/face-2.jpg";
import MyModal from "../../components/MyModal";
import DocumentAssigned from "../../components/uploads/DocumentAssigned";
import { dateEndContract, daysToEndContract } from "../../helpers/dateHelper";
import { formatCurrency } from "../../helpers/moneyFormat";
import { plan } from "../../helpers/plans";

const User = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idProfile, setIdProfile] = useState(null);
  const [aport, setAport] = useState({ title: "", show: false });
  const [entry, setEntry] = useState({ title: "", show: false });
  const [withdraw, setWithdraw] = useState({ title: "", show: false });
  const [reinvestment, setReinvestment] = useState({ title: "", show: false });
  const queryClient = useQueryClient();
  const [hiddenDocument, setHiddenDocument] = useState(false);

  const { data: dataInvestor, isLoading } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const { data: dataManager } = useManagerFetchRequest(investor?.manager);
  const manager = dataManager?.data;

  const put = async (dataForm) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/v1/transaction/create`,
      dataForm,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return response.data;
  };
  const { mutate } = useMutation(put, {
    onSuccess: (data) => {
      setIsModalVisible(false);
      form.resetFields();
      queryClient.invalidateQueries("adminInvestor");
      api.success({
        message: "Sucesso",
        description: "Lançamento feito com sucesso.",
      });
    },
    onError: (error) => {
      api.error({
        message: `Error: [${error.response.data.status}] ${error.response.data.name}`,
        description: error.response.data.message,
      });
    },
  });
  const onFinish = (values) => {
    mutate(values);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAport({ title: "", show: false });
    setEntry({ title: "", show: false });
    setWithdraw({ title: "", show: false });
    setReinvestment({ title: "", show: false });
  };

  const handleHiddenDocument = () => {
    setHiddenDocument(!hiddenDocument);
  };

  const arrayEntrada22 = investor?.transactions?.map((transaction) => ({
    amount: transaction?.amount_money,
    month: transaction?.month_year,
    type: transaction?.type_transaction,
    percent: transaction?.percent,
  }));

  const dadosAgrupados222 = arrayEntrada22?.reduce(
    (agrupados, dado) => {
      const { amount, month, type, percent } = dado;
      const valor = parseFloat(amount);

      if (month && !isNaN(valor)) {
        if (type === 1) {
          if (agrupados.percent[month]) {
            agrupados.percent[month] += percent;
          } else {
            agrupados.percent[month] = percent;
          }
        } else {
          if (agrupados.ganhos[month]) {
            agrupados.ganhos[month] += valor;
          } else {
            agrupados.ganhos[month] = valor;
          }
        }
      }

      return agrupados;
    },
    { percent: {}, ganhos: {} }
  );

  const arrayResultadoPercent = Object.keys(
    dadosAgrupados222?.percent || {}
  ).map((data) => ({
    amount: dadosAgrupados222.percent[data],
    mes: data,
  }));

  const arrayResultadoGanhos = Object.keys(dadosAgrupados222?.ganhos || {}).map(
    (data) => ({
      amount: dadosAgrupados222.ganhos[data],
      mes: data,
    })
  );

  const narrXPercent = arrayResultadoPercent?.map((a) => a.mes) || [];
  const narrDataPercent = arrayResultadoPercent?.map((a) => a.amount) || [];

  const narrXGanhos = arrayResultadoGanhos?.map((a) => a.mes) || [];
  const narrDataGanhos = arrayResultadoGanhos?.map((a) => a.amount) || [];

  const chart = {
    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 2,
          // dataLabels: {
          //   position: "top"
          // }
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return formatCurrency(val);
        },
        offsetY: 0,
        style: {
          fontSize: "12px",
          colors: ["#eee"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#1890aa"],
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2,
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#900"],
        },
      },
      xaxis: {
        categories: narrXGanhos,
      },
      yaxis: {
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#000"],
          },
          formatter: function (val) {
            return formatCurrency(val);
          },
        },
      },
      title: {
        text: "Ganho Mensal",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return formatCurrency(val);
          },
        },
      },
    },

    series: [
      {
        name: "Valor fechamento Mensal",
        data: narrDataGanhos,
        color: "#1890ff",
      },
    ],
  };

  const chart2 = {
    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `${val} %`;
        },
        offsetY: -20,
        style: {
          fontSize: "10px",
          colors: ["#555"],
          transform: "rotate(90)",
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#1890aa"],
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2,
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#900"],
        },
      },
      xaxis: {
        categories: narrXPercent,
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#000"],
          },
          formatter: function (val) {
            return `${val} %`;
          },
        },
      },
      title: {
        text: "Porcentagem mes",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${val} %`;
          },
        },
      },
    },

    series: [
      {
        name: "Porcentagem mensal",
        data: narrDataPercent,
        color: "#1890ff",
      },
    ],
  };

  if (isLoading) {
    return "caregando...";
  }

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{
          backgroundImage: "url(" + investor?.imageUrl || BgProfile + ")",
        }}
      ></div>
      {contextHolder}
      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={24} className="col-info">
              <Avatar.Group>
                <Avatar
                  size={74}
                  shape="square"
                  src={
                    investor?.imageUrl ||
                    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{investor?.name}</h4>
                  <p>{plan(investor?.plan)}</p>
                </div>
              </Avatar.Group>
            </Col>
          </Row>
        }
      ></Card>

      <Row gutter={[24, 0]}>
        <Col span={24} md={24} className="mb-24 ">
          {/* <Card
            bordered={true}
            className=" h-full"
            bodyStyle={{
              backgroundColor: "#99900000",
              boxShadow: "0 0 0 transparent"
            }}
          > */}
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Valor total aportado</span>
                      <Title level={3}>
                        {formatCurrency(investor?.apports)}
                        <br />
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <RiseOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Valor atual da carteira</span>
                      <Title level={3}>
                        {formatCurrency(investor?.wallet?.amount)}
                        <br />
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <LineChartOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Valor ja sacado</span>
                      <Title level={3}>
                        {formatCurrency(investor?.wallet?.expense)}
                        <br />
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <FallOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
              <Card bordered={false} className="criclebox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>Tempo de contrato</span>
                      <Title level={5}>
                        {investor?.start_of_contract}
                        {` - ${dateEndContract(investor?.start_of_contract)}`}
                        <br />
                        <small className="bnb">{`(${daysToEndContract(
                          investor?.start_of_contract,
                          dateEndContract(investor?.start_of_contract)
                        )} dias restantes)`}</small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">
                        <CalendarOutlined />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>

            {/* <Col span={4}>
              <Card bordered={true}>
                <Statistic
                  title="Valor já aportado"
                  value={formatCurrency(investor?.apports)}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </Card>
            </Col> */}
            {/* <Col span={4}>
              <Card bordered={true}>
                <Statistic
                  title="Valor na carteira"
                  value={formatCurrency(investor?.wallet?.amount)}
                  valueStyle={{
                    color: "#3f8600",
                  }}
                />
              </Card>
            </Col> */}
            {/* <Col span={4}>
              <Card bordered={true}>
                <Statistic
                  title="Valor ja sacado"
                  value={formatCurrency(investor?.wallet?.expense)}
                  valueStyle={{
                    color: "#cf1322",
                  }}
                />
              </Card>
            </Col> */}
            {/* <Col span={6}>
              <Card bordered={true}>
                {investor?.start_of_contract ? (
                  <Statistic
                    title={`Tempo de contrato (${daysToEndContract(
                      investor?.start_of_contract,
                      dateEndContract(investor?.start_of_contract)
                    )} dias restantes)`}
                    value={investor?.start_of_contract}
                    suffix={` - ${dateEndContract(
                      investor?.start_of_contract
                    )}`}
                    groupSeparator=".."
                  />
                ) : (
                  ""
                )}
              </Card>
            </Col> */}
          </Row>
          {/* </Card> */}
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col span={24} md={12} className="mb-24 ">
          <Card bordered={false} className="criclebox h-full">
            <ReactApexChart
              className=""
              options={chart.options}
              series={chart.series}
              type="bar"
              height={220}
            />
          </Card>
        </Col>
        <Col span={12} md={12} className="mb-24 ">
          <Card bordered={false} className="criclebox h-full">
            <ReactApexChart
              className=""
              options={chart2.options}
              series={chart2.series}
              type="bar"
              height={220}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col span={24} md={12} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Informações</h6>}
            className="header-solid h-full card-profile-information"
            // extra={<Button type="link">{pencil}</Button>}
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <hr className="my-25" />

            <Descriptions>
              <Descriptions.Item label="Documento" span={3}>
                {hiddenDocument ? (
                  <>
                    <EyeInvisibleOutlined
                      onClick={handleHiddenDocument}
                      className="mr-5"
                    />{" "}
                    {investor?.document}{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <EyeOutlined
                      onClick={handleHiddenDocument}
                      className="mr-5"
                    />{" "}
                    ******{" "}
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Nome" span={3}>
                {investor?.name}
              </Descriptions.Item>
              <Descriptions.Item label="Telefone" span={3}>
                {investor?.phone}
              </Descriptions.Item>
              <Descriptions.Item label="E-mail" span={3}>
                {investor?.email}
              </Descriptions.Item>
              <Descriptions.Item label="Pix" span={3}>
                {investor?.pix || "nao cadastrado"}
              </Descriptions.Item>
              <Descriptions.Item label="Documento" span={3}>
                {investor?.link_document ? (
                  <a
                    href={`${investor?.link_document}?ik-attachment=true`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FilePdfOutlined /> arquivo.pdf
                  </a>
                ) : (
                  "nao cadastrado"
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Enviar documento" span={3}>
                <DocumentAssigned
                  id={investor?.id}
                  title={investor?.document}
                />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col span={24} md={12} className="mb-24">
          <Card
            bordered={false}
            title={<h6 className="font-semibold m-0">Gerente de conta</h6>}
            className="header-solid h-full card-profile-information"
            // extra={<Button type="link">{pencil}</Button>}
            bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
          >
            <List
              itemLayout="horizontal"
              dataSource={[
                {
                  title: manager?.name,
                  avatar: convesionImg,
                  description: "",
                },
              ]}
              split={false}
              className="conversations-list"
              renderItem={(item) => (
                <List.Item actions="">
                  <List.Item.Meta
                    // avatar={
                    //   <Avatar shape="square" size={48} src={item.avatar} />
                    // }
                    title={item?.title}
                    description={item?.description}
                  />
                </List.Item>
              )}
            />
            <Descriptions>
              <Descriptions.Item label="Telefone" span={3}>
                {manager?.phone}
              </Descriptions.Item>
              <Descriptions.Item label="Whatsapp" span={3}>
                {manager?.whatsapp}
              </Descriptions.Item>
              <Descriptions.Item label="E-mail" span={3}>
                {manager?.email}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <MyModal
          isVisible={isModalVisible}
          onCancel={handleCancel}
          onOk={onFinish}
          title={
            <h2 style={{ textAlign: "center" }}>
              {aport?.title ||
                entry?.title ||
                withdraw?.title ||
                reinvestment?.title}
            </h2>
          }
          okButtonProps={{
            hidden: true,
          }}
          cancelButtonProps={{
            disabled: true,
          }}
        >
          <Form
            preserve={false}
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            fields={[
              {
                name: ["investor"],
                value: investor?.user_id,
              },
            ]}
            form={form}
          >
            {aport?.show && (
              <Col>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="amount">
                      <Input prefix="$" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false} hidden>
                  <Col flex="auto">
                    <Form.Item name="investor">
                      <Input prefix="" />
                    </Form.Item>
                    <Form.Item name="type" initialValue="2">
                      <Input prefix="" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

            {entry?.show && (
              <Col>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="percentage">
                      <Input prefix="%" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false} hidden>
                  <Col flex="auto">
                    <Form.Item name="investor">
                      <Input prefix="" />
                    </Form.Item>
                    <Form.Item name="type" initialValue="1">
                      <Input prefix="" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

            {withdraw?.show && (
              <Col>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="amount">
                      <Input prefix="$" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false} hidden>
                  <Col flex="auto">
                    <Form.Item name="investor">
                      <Input prefix="" />
                    </Form.Item>
                    <Form.Item name="type" initialValue="5">
                      <Input prefix="" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}

            {reinvestment?.show && (
              <>
                <Col>
                  <Row wrap={false} gutter={[16, 16]}>
                    <Col flex="auto">
                      <Descriptions title="" layout="vertical" bordered>
                        <Descriptions.Item label="Valor a ser reinvestido">
                          {investor?.wallet?.available_for_withdrawal}
                          <Form.Item
                            name="amount"
                            initialValue={
                              investor?.wallet?.available_for_withdrawal
                            }
                            hidden
                          >
                            <Input prefix="" />
                          </Form.Item>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                  <Row wrap={false} hidden>
                    <Col flex="auto">
                      <Form.Item name="investor">
                        <Input prefix="" />
                      </Form.Item>
                      <Form.Item name="type" initialValue="8">
                        <Input prefix="" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <br />
              </>
            )}

            <Col flex="auto">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Salvar
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </MyModal>
      </Row>
    </>
  );
};

export default User;
