import { Avatar, Card, Col, Radio, Row } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { useProfileOnlineFetchRequest } from "../../Hooks/profile/useProfile.query";
import convesionImg2 from "../../assets/images/face-4.jpg";

const Profile = () => {
  const { data: dataProfile, isLoading } = useProfileOnlineFetchRequest();
  const profile = dataProfile?.data?.data;

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{
          backgroundImage: "url(" + profile?.imageUrl || convesionImg2 + ")",
        }}
      ></div>
      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={20} className="col-info">
              <Avatar.Group>
                <Avatar
                  size={74}
                  shape="square"
                  src={
                    profile?.imageUrl ||
                    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">{profile?.name}</h4>
                  {/* <p>{plan(profile?.plan)}</p> */}
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Radio.Group>
                <Radio.Button
                  value="editar"
                  // onClick={() => handleClickEdit(id)}
                >
                  <NavLink to="editar">EDITAR</NavLink>
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        }
      ></Card>
    </>
  );
};

export default Profile;
