import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAdvices = async () => {
  try {
    const data = await axios.get(`${process.env.REACT_APP_API}/v1/advice`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          process.env.REACT_APP_ACCESS_TOKEN
        )}`,
      },
    });
    return data;
  } catch (error) {
    console.log("error");
  }
};

const getAdvicesDoc = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/advice/submitted-document?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log("error");
  }
};

const getAdvicesBankDoc = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/advice/bankdocument?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log("error");
  }
};

const postAdvice = async (values) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API}/v1/advice/create`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useAdviceFetchRequest() {
  return useQuery(["advicesManager"], () => getAdvices(), {
    onSuccess: (data) => {
      return data.data.data;
    },
  });
}

export function useAdviceDocFetchRequest(id) {
  return useQuery(["advicesDocManager"], () => getAdvicesDoc(id), {
    onSuccess: (data) => {
      return data.data.data;
    },
  });
}

export function useAdviceBankFetchRequest(id) {
  return useQuery(["advice-manager-bank"], () => getAdvicesBankDoc(id), {
    onSuccess: (data) => {
      return data.data.data;
    },
  });
}

export function useAdvicePostRequest(data) {
  return postAdvice(data);
}
